






































































































































































































import useNumber from "@/use/number";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  nextTick,
  watch,
  onMounted,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import _ from "lodash";

export default defineComponent({
  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    MVehicleSelect: () => import("@/components/molecules/m-vehicle-select.vue"),
  },
  setup(_props, { root }) {
    const { formatNumber } = useNumber();

    const state = reactive({
      loading: false,
      success: false,
      error: null as null | string,
    });

    const currentFile = ref<File | null>(null);

    const selectedItems = ref<any[]>([]);
    const parsedItems = ref<any[]>([]);

    const types = [
      { text: "Orlen", value: "orlen" },
      { text: "BP", value: "bp" },
      { text: "Flotex", value: "flotex" },
    ];

    const cancel = () => {
      parsedItems.value = [];
      selectedItems.value = [];
      state.error = null;
      state.success = false;
    };

    const currentType = computed({
      get: () => (root.$route.query.type as string) || null,
      set: (value) =>
        root.$router.replace({ query: { type: value } }).catch(() => []),
    });

    watch(currentType, (type) => {
      if (type && !types.map((t) => t.value).includes(type)) {
        root.$router.replace({ query: { type: null } }).catch(() => []);
      }
    });

    onMounted(() => {
      if (
        !types.map((t) => t.value).includes(root.$route.query.type?.toString())
      ) {
        cancel();
        root.$router.replace({ query: { type: null } }).catch(() => []);
      }
    });

    const parsedItemsHeaders = [
      { text: "Pojazd", value: "vehicle", sortable: false },
      { text: "Przebieg", value: "mileage", sortable: false },
      { text: "Numer faktury", value: "invoiceNumber", sortable: false },
      { text: "Nazwa produktu", value: "productName", sortable: false },
      { text: "Rodzaj produktu", value: "productType", sortable: false },
      { text: "Data", value: "date", sortable: false },
      { text: "Cena (brutto)", value: "price", sortable: false },
      { text: "Ilość", value: "amount", sortable: false },
      { text: "Suma (netto)", value: "total", sortable: false },
      { text: "", value: "actions", width: 1, align: "end", sortable: false },
    ];

    const productTypes = [
      { value: "petrol", text: "Paliwo" },
      { value: "carwash", text: "Myjnia" },
      { value: "tolls", text: "Opłaty drogowe" },
      { value: "other", text: "Inne" },
    ];

    const editor = reactive({
      dialog: false,
      editedIndex: -1,
      editedItem: {
        vehicle: null,
        mileage: null,
        invoiceNumber: null,
        productName: null,
        productType: null,
        date: null,
        amount: null,
        price: null,
        total: null,
      } as any,
      defaultItem: {
        vehicle: null,
        mileage: null,
        invoiceNumber: null,
        productName: null,
        productType: null,
        date: null,
        amount: null,
        price: null,
        total: null,
      },
    });

    const formTitle = computed(() =>
      editor.editedIndex === -1 ? "Nowy element" : "Edycja elementu"
    );

    const submitFile = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      state.error = null;

      const data = new FormData();

      if (currentFile.value) data.append("file", currentFile.value);
      if (currentType.value) data.append("type", currentType.value);

      axiosInstance
        .post("fuel-card/upload", data)
        .then(({ data }) => {
          const items = data.converted.map((convertedItem: any) => {
            const item = { id: uuid(), ...convertedItem };
            return {
              value: Object.assign({}, editor.defaultItem, item),
              default: Object.assign({}, editor.defaultItem, item),
            };
          });
          parsedItems.value = items;
          nextTick(() => (selectedItems.value = items));
        })
        .catch((error) => {
          if (error?.response?.status) {
            switch (error.response.status) {
              case 406:
                state.error =
                  "Podany plik zapisany jest w niepoprawnym formacie.";
                break;
              case 409:
                state.error =
                  "Podany plik nie zgadza się ze strukturą wybranego dostawcy.";
                break;
              default:
                state.error = "Nie udało się przetworzyć pliku.";
            }
          } else {
            state.error =
              "Nie udało się wysłać pliku. Sprawdź połączenie i spróbuj ponownie.";
          }
        })
        .finally(() => (state.loading = false));
    };

    const editItem = (item: any) => {
      editor.editedIndex = parsedItems.value.indexOf(item);
      editor.editedItem = Object.assign({}, item.value);
      editor.dialog = true;
    };

    const close = () => {
      editor.dialog = false;
      nextTick(() => {
        editor.editedItem = Object.assign({}, editor.defaultItem);
        editor.editedIndex = -1;
      });
    };

    const save = () => {
      if (editor.editedIndex > -1)
        Object.assign(
          parsedItems.value[editor.editedIndex].value,
          editor.editedItem
        );
      else
        parsedItems.value.push({
          value: editor.editedItem,
          default: editor.editedItem,
        });
      close();
    };

    const isValid = () => {
      const errors: { itemIndex: number }[] = [];
      const items = selectedItems.value;

      items.forEach((value, index) => {
        const item = value.value;
        if (
          !item?.vehicle?.id ||
          !item?.mileage ||
          !item?.invoiceNumber ||
          !item?.productName ||
          !item?.productType ||
          !item?.date ||
          !item?.price ||
          !item?.amount ||
          !item?.total
        ) {
          errors.push({ itemIndex: index });
        }
      });

      if (errors.length) return false;
      return true;
    };

    const submitImport = () => {
      state.error = null;
      if (isValid()) {
        const axiosInstance = root.$store.getters[
          "api/getInstance"
        ] as AxiosInstance;
        const items = selectedItems.value;

        state.loading = true;

        const purchases = items.map((item) => ({
          vehicle: { id: item.value?.vehicle?.id },
          mileage: item.value?.mileage,
          invoiceNumber: item.value?.invoiceNumber,
          productName: item.value?.productName,
          productType: item.value?.productType,
          date: item.value?.date,
          price: item.value?.price,
          amount: item.value?.amount,
          total: item.value?.total,
          type: currentType.value,
        }));

        axiosInstance
          .post("fuel-card", { purchases })
          .then(() => {
            state.success = true;
          })
          .catch((error) => {
            if (error.response) {
              switch (error.response.status) {
                default:
                  state.error = "Nie udało się dodać zestawienia transakcji.";
              }
            } else {
              state.error =
                "Nie udało się wysłać zestawienia transakcji. Sprawdź połączenie i spróbuj ponownie.";
            }
          })
          .finally(() => (state.loading = false));
      } else {
        state.error =
          "Pośród wybranych elementów znajdują się błędy. Popraw je i spróbuj ponownie.";
      }
    };

    const hasItemChanged = (index: number) =>
      parsedItems.value[index] &&
      !_.isEqual(
        parsedItems.value[index].value,
        parsedItems.value[index].default
      );

    const resetItem = (index: number) => {
      if (parsedItems.value[index])
        Object.assign(
          parsedItems.value[index].value,
          parsedItems.value[index].default
        );
      close();
    };

    const changePricing = (field: "amount" | "price" | "total") => {
      switch (field) {
        case "amount":
        case "price":
          editor.editedItem.total =
            Math.round(
              editor.editedItem.price * editor.editedItem.amount * 100
            ) / 100;
          break;
        case "total":
          editor.editedItem.amount =
            Math.round(
              (editor.editedItem.total / editor.editedItem.price) * 100
            ) / 100;
          break;
        default:
          state.error = "Wystąpił błąd.";
      }
    };

    return {
      state,
      formatNumber,
      selectedItems,
      parsedItems,
      parsedItemsHeaders,
      productTypes,
      currentType,
      currentFile,
      editor,
      formTitle,
      types,
      submitFile,
      editItem,
      close,
      save,
      submitImport,
      cancel,
      hasItemChanged,
      resetItem,
      changePricing,
    };
  },
});
